import React from 'react'
import Helmet from 'react-helmet'
import { Player, ControlBar } from 'video-react'

import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import t3ServicesImage from '../assets/images/t3-services.png'
import wordpressLogo from '../assets/images/wordpress-logo.png'
import jqueryLogo from '../assets/images/jquery-logo.png'
import scssLogo from '../assets/images/scss-logo.png'
import gulpLogo from '../assets/images/gulp-logo.png'
import dockerLogo from '../assets/images/docker-logo.png'
import hubspotLogo from '../assets/images/hubspot-logo.png'
import t3Logo from '../assets/images/t3-green.png'
import t3Video from '../assets/videos/t3-video.mp4'

const Landing = props => (
  <Layout>
    <Helmet
      title="Tyler Kemme | T3"
      meta={[
        {
          name: 'description',
          content: 'Wordpress Site Redesign',
        },
        {
          name: 'keywords',
          content: 't3, wordpress, jquery, scss, gulp, docker, hubspot',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>

    <BannerLanding
      title="T3"
      description="Wordpress Site Redesign"
      to="https://www.t-3.com/"
      backgroundImage={t3Logo}
    />

    <div id="main" className="landing-page">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>The Project</h2>
          </header>
          <p>
            When T3 needed to redesign the company website, we chose to make a
            Wordpress site that leveraged Hubspot's CRM platform for form
            submissions and analytics. The front-end design was accomplished
            using JQuery and SCSS according to comps created by our designers.
            We used Docker to develop the site locally and WPEngine to host the
            website.
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <div className="logo-soup">
            <img
              src={wordpressLogo}
              alt="Wordpress logo"
              className="logo-soup-individual"
            />
            <img
              src={jqueryLogo}
              alt="JQuery logo"
              className="logo-soup-individual"
            />
            <img
              src={scssLogo}
              alt="SCSS logo"
              className="logo-soup-individual"
            />
            <img
              src={gulpLogo}
              alt="Gulp logo"
              className="logo-soup-individual"
            />
            <img
              src={dockerLogo}
              alt="Docker logo"
              className="logo-soup-individual"
            />
            <img
              src={hubspotLogo}
              alt="Hubspot logo"
              className="logo-soup-individual"
            />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h2>Technologies Used</h2>
              </header>
              <p>
                This Wordpress site was made using the following front-end
                technologies and developed locally using Docker. We used Hubspot
                as a CRM and it was hosted through WPEngine
                <br />
                <br />
                <ul>
                  <li>
                    <a
                      href="https://wordpress.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wordpress
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://jquery.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      JQuery
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://sass-lang.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SCSS
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://gulpjs.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Gulp
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.docker.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Docker
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.hubspot.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Hubspot
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </section>
        <section>
          <a
            href="https://www.t-3.com/"
            className="image"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Player autoPlay muted loop>
              <source src={t3Video} />
              <ControlBar disableCompletely />
            </Player>
          </a>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>How I contributed</h3>
              </header>
              <p>
                On this site I was responsible for the front page
                design/animation and also for developing half of the other pages
                on the site. In addition to front-end design, I was also
                responsible for setting up Wordpress using the{' '}
                <a
                  href="https://www.advancedcustomfields.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ACF
                </a>{' '}
                plugin so that T3's marketing team could add content to the site
                without needing help from the development team. We used
                Hubspot's API for lead collection and analytics. I also setup
                our local development environment using Gulp and Docker and was
                responsible for deploying the website to WPEngine.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={t3ServicesImage} alt="T3 Services Page" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Additional Information</h3>
              </header>
              <p>
                When we developed this site, WPEngine hadn't yet created their{' '}
                <a
                  href="https://wpengine.com/support/devkit/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  devkit
                </a>{' '}
                or partnered with{' '}
                <a
                  href="https://localwp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Local
                </a>
                . Because of this, I had to develop my own scripts for pushing
                our code to GitLab and deploying to WPEngine. The script I made
                created a temporary git branch containing only the theme and
                plugin files that had been changed and then pushed those files
                to WPEngine. I also added the ability to optionally push media
                assets to WPEngine so that the uploads folder was only deployed
                when absolutely necessary.
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Landing
